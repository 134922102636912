:root {
  --background-color: #ebeffa;
}

body{
  background-color: var(--background-color);
}

.container {
  display: flex;
  flex-direction: column;
}

/* Search page */
.App-search-header {
  display: flex;
  justify-content: center;
  margin: 120px 0 45px 0;
}

/*Border radius for the search bar*/
.App-search-input .ant-input {
  border-radius: 50px;
}
.App-search-input .ant-input-search-button {
  border-radius: 2px 50px 50px 2px !important;
}

/* Removes border line between search input and search bar */
.App-search-input .ant-btn {
  border-width: 1px 1px 1px 0;
}

/*Ensures the background colour of the search bar button matches the page background colour */
.ant-input-group-addon {
  background-color: var(--background-color);
}

.App-search-results {
  min-height: 17em;
  margin: 0 0.7em;
  font-size: calc(10px + 2vmin);
}

.ant-menu-light .ant-menu-item:hover {
  background: #ccc;
}

.ant-menu-item {
  margin: 0 0 0 0 !important;
}

/* Property page */

/* Desktop section */
@media (min-width: 800px) {
  .App-property-body {
    max-height: 100vh;
    display: grid;
    grid-template-columns: 30% 30% auto;
    grid-template-rows: auto auto;
    column-gap: 0.5rem;
    row-gap: 0.5rem;
  }

  .App-property-map {
    grid-row: 1 / 4;
    grid-column: 3 / 3;
    align-items: center;
  }

  #property-building-info-card {
    grid-row: 2 / 4;
    grid-column: 1 / 3;
    align-items: center;
  }
}

/* Mobile section */
@media (max-width: 800px) {
  .App-property-body {
    max-height: 100vh;
    display: flex;
    flex-wrap: wrap;
    column-gap: 0.5rem;
    row-gap: 0.5rem;
  }

  .App-property-card {
    width: 100%;
  }
}

/* Moves the back button up a tiny bit such that it lines up better with the back button title */
.ant-page-header-back-button {
  padding: 0 0 3px 0 !important;
}

.App-loading-icon {
  width: 100%;
  min-height: 32.5rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

#copyUprnBig {
  position: relative;
  bottom: 5px;
}

.anticon-copy {
  position: relative;
  bottom: 2px;
  right: 2px;
}
